<script lang="ts" setup>
//import { doc, getDoc, getFirestore, Timestamp } from "@firebase/firestore";
/*
interface Rating {
    ts: Timestamp
    appStore: number
    playStore: number
}*/

import { useI18n } from "vue-i18n";
import { storeRating } from "~~/utils/store-rating";


const i18n = useI18n()    
const appStore = ref();//4.9
const playStore = ref();//4.5

const storeLoading = ref(true)

const decimalFormat = new Intl.NumberFormat(i18n.locale.value, {  maximumFractionDigits: 1, minimumFractionDigits: 1  })
const rating = storeRating();
appStore.value = decimalFormat.format(rating.appStore)
playStore.value = decimalFormat.format(rating.playStore)
				
        /*
        const db = getFirestore();

        const getRating = async()=> {
            const querySnapshot = await getDoc(doc(db, `appRating/current`));

            if(querySnapshot.exists()){
                const rating = <Rating>querySnapshot.data()
                appStore.value = decimalFormat.format(rating.appStore || 4.9)
                playStore.value = decimalFormat.format(rating.playStore || 4.3)
            }else{
                appStore.value = decimalFormat.format( 5)
                playStore.value = decimalFormat.format( 4.5)
            }
            storeLoading.value = false
        }
        
        getRating()
        */
</script>
<template>
    <div class="flex flex-row items-center text-xs text-black/60 font-medium flex-nowrap" >
   
            <img src="@/assets/rating_star.svg" alt="star rating" width="16" height="16" />
            <p class="ml-1 mr-6">{{$t('home.teaser.rating_app_store', {'rating': appStore})}}</p>

            <img src="@/assets/rating_star.svg" alt="star rating" width="16" height="16" />
            <p class="ml-1">{{$t('home.teaser.rating_play_store', {'rating': playStore})}}</p>
   
    </div>
</template>
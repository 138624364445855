interface Rating {
    appStore: number
    appStoreCount: number
    playStore: number
    playStoreCount: number
}
export const  storeRating = () => {
    return <Rating>{
        appStore: 4.9,
        appStoreCount: 24,
        playStore: 4.6,
        playStoreCount: 183, 
    }
};